<template>
  <div v-if="!loading">
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('remissions') }}
        </h1>
      </b-col>
    </b-row>
    <b-row v-if="purchaseOrders.length > 0">
      <b-col>
        <purchase-orders-table :purchase-orders="purchaseOrders" />
      </b-col>
    </b-row>
    <b-row v-if="purchaseOrders.length === 0">
      <b-col>
        <h4 class="display-4">
          No hay Cotizaciones 🧟
        </h4>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PurchaseOrdersTable from '@/components/purchaseOrders/PurchaseOrdersTable.vue'

export default {
  components: {
    PurchaseOrdersTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('purchaseOrders', ['purchaseOrders']),
    ...mapGetters('projects', ['project']),
  },

  created() {
    this.fetchPurchaseOrders({
      quoteId: this.$route.params.quoteId,
    })
  },

  methods: {
    ...mapActions('purchaseOrders', ['fetchPurchaseOrders']),
  },
}
</script>

<style></style>
