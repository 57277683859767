<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-table
        responsive
        :items="purchaseOrders"
        :fields="fields"
      >
        <template v-slot:[`cell(quote.folio)`]="data">
          <div class="text-primary font-weight-bolder">
            <router-link
              :to="{
                name: 'purchaseOrder',
                params: { quoteId: data.item.quote.id },
              }"
            >
              #{{ data.value }}
            </router-link>
          </div>
        </template>
        <template v-slot:[`cell(quote.address)`]="data">
          {{ data.value.formatted_address }}
        </template>
        <template v-slot:[`cell(quote.published_at)`]="data">
          {{ data.value | date }}
        </template>
        <template #cell(shipping_status)="data">
          <b-badge :variant="$helpers.shippingStatusColor(data.value)">
            {{ $helpers.shippingStatusName(data.value) }}
          </b-badge>
        </template>
        <template #cell(document_attachment)="data">
          <b-link
            target="_blank"
            class="d-flex align-items-center"
            :href="data.item.document_attachment"
          >
            <span class="h5 text-primary">
              {{ $t('remission') }}
            </span>
          </b-link>
        </template>
        <template #cell(tools)="data">
          <div
            v-if="data.item.shipping_status !== 'shipping_pending'"
            class="d-flex"
          >
            <b-button
              v-b-modal.confirmReject
              :disabled="
                !(data.item.shipping_status === 'awaiting_shipping_approval')
              "
              variant="outline-danger"
              size="sm"
              class="mr-1"
              @click="selectedPurchaseOrder = data.item"
            >
              {{ $t('reject') }}
            </b-button>

            <!-- Button: Add Payment -->
            <b-button
              v-b-modal.confirmApprove
              :disabled="
                !(data.item.shipping_status === 'awaiting_shipping_approval')
              "
              variant="outline-success"
              size="sm"
              @click="selectedPurchaseOrder = data.item"
            >
              {{ $t('approve') }}
            </b-button>
          </div>
        </template>
      </b-table>
    </b-overlay>
    <confirmation-modal
      v-if="
        selectedPurchaseOrder.shipping_status === 'awaiting_shipping_approval'
      "
      :id="'confirmApprove'"
      :variant="'success'"
      @confirm="approveShip(selectedPurchaseOrder.id)"
    >
      <template v-slot:content>
        {{ $t('approveShipmentConfirmation') }}
      </template>
    </confirmation-modal>

    <confirmation-modal
      v-if="
        selectedPurchaseOrder.shipping_status === 'awaiting_shipping_approval'
      "
      :id="'confirmReject'"
      :variant="'danger'"
      @confirm="rejectShip(selectedPurchaseOrder.id)"
    >
      <template v-slot:content>
        {{ $t('rejectShipmentConfirmation') }}
      </template>
    </confirmation-modal>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    BTable,
    ConfirmationModal,
  },
  props: {
    purchaseOrders: {
      type: Array,
      default() {
        return []
      },
    },
    noTools: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      selectedPurchaseOrder: {},
      loading: false,
      auctioneerFields: [
        {
          key: 'quote.folio',
          label: 'Folio',
        },
        {
          key: 'supplier_branch.name',
          label: this.$t('branch'),
        },
        {
          key: 'quote.published_at',
          label: this.$t('date'),
        },
        {
          key: 'shipping_status',
          label: this.$t('status'),
        },
      ],
      adminFields: [
        {
          key: 'quote.folio',
          label: 'Folio',
        },
        {
          key: 'supplier_branch.name',
          label: this.$t('branch'),
        },
        {
          key: 'quote.published_at',
          label: this.$t('date'),
        },
        {
          key: 'shipping_status',
          label: this.$t('status'),
        },
        {
          key: 'document_attachment',
          label: this.$t('file'),
        },
        {
          key: 'tools',
          label: this.$t('tools'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapState(['apiUrl']),
    fields() {
      if (this.currentUser.role_name === 'admin') return this.adminFields
      return this.auctioneerFields
    },
  },

  methods: {
    ...mapActions('purchaseOrders', [
      'fetchPurchaseOrders',
      'approveShipment',
      'rejectShipment',
    ]),

    approveShip(purchaseOrderId) {
      this.loading = true
      this.approveShipment({
        purchaseOrderId,
      }).then(() => {
        this.fetchPurchaseOrders({
          quoteId: this.$route.params.quoteId,
        }).then(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('paymentApproved'),
              icon: 'CreditCardIcon',
              variant: 'success',
            },
          })
        })
      })
    },

    rejectShip(purchaseOrderId) {
      this.rejectShipment({
        purchaseOrderId,
      }).then(() => {
        this.fetchPurchaseOrders({
          quoteId: this.$route.params.quoteId,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('paymentRejected'),
            icon: 'CreditCardIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
