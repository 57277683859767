var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"overflow-hidden",attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[_c('b-table',{attrs:{"responsive":"","items":_vm.purchaseOrders,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(quote.folio)",fn:function(data){return [_c('div',{staticClass:"text-primary font-weight-bolder"},[_c('router-link',{attrs:{"to":{
              name: 'purchaseOrder',
              params: { quoteId: data.item.quote.id },
            }}},[_vm._v(" #"+_vm._s(data.value)+" ")])],1)]}},{key:"cell(quote.address)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.formatted_address)+" ")]}},{key:"cell(quote.published_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.value))+" ")]}},{key:"cell(shipping_status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.$helpers.shippingStatusColor(data.value)}},[_vm._v(" "+_vm._s(_vm.$helpers.shippingStatusName(data.value))+" ")])]}},{key:"cell(document_attachment)",fn:function(data){return [_c('b-link',{staticClass:"d-flex align-items-center",attrs:{"target":"_blank","href":data.item.document_attachment}},[_c('span',{staticClass:"h5 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('remission'))+" ")])])]}},{key:"cell(tools)",fn:function(data){return [(data.item.shipping_status !== 'shipping_pending')?_c('div',{staticClass:"d-flex"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.confirmReject",modifiers:{"confirmReject":true}}],staticClass:"mr-1",attrs:{"disabled":!(data.item.shipping_status === 'awaiting_shipping_approval'),"variant":"outline-danger","size":"sm"},on:{"click":function($event){_vm.selectedPurchaseOrder = data.item}}},[_vm._v(" "+_vm._s(_vm.$t('reject'))+" ")]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.confirmApprove",modifiers:{"confirmApprove":true}}],attrs:{"disabled":!(data.item.shipping_status === 'awaiting_shipping_approval'),"variant":"outline-success","size":"sm"},on:{"click":function($event){_vm.selectedPurchaseOrder = data.item}}},[_vm._v(" "+_vm._s(_vm.$t('approve'))+" ")])],1):_vm._e()]}}],null,true)})],1),(
      _vm.selectedPurchaseOrder.shipping_status === 'awaiting_shipping_approval'
    )?_c('confirmation-modal',{attrs:{"id":'confirmApprove',"variant":'success'},on:{"confirm":function($event){return _vm.approveShip(_vm.selectedPurchaseOrder.id)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('approveShipmentConfirmation'))+" ")]},proxy:true}],null,false,3966528184)}):_vm._e(),(
      _vm.selectedPurchaseOrder.shipping_status === 'awaiting_shipping_approval'
    )?_c('confirmation-modal',{attrs:{"id":'confirmReject',"variant":'danger'},on:{"confirm":function($event){return _vm.rejectShip(_vm.selectedPurchaseOrder.id)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('rejectShipmentConfirmation'))+" ")]},proxy:true}],null,false,3357845720)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }